<template>
  <div
    class="site-wrapper"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
  >
    <template v-if="!loading">
      <main-navbar />
      <main-sidebar />
      <div class="site-content__wrapper">
        <MainContent />
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import MainNavbar from "./main-navbar";
import MainSidebar from "./main-sidebar.vue";
import MainContent from "./main-content.vue";
import { GetParameter } from "@/api/system";
export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    MainNavbar,
    MainSidebar,
    MainContent,
  },
  computed: {
    ...mapState("common", {
      menuList: (state) => state.menuList,
      mainTabsActiveName: (state) => state.mainTabsActiveName,
    }),
    ...mapState("user", ["userName", "name", "officeName", "name"]),
  },
  mounted() {
    this.geUserInfo();
    this.GetConfig();
  },
  methods: {
    ...mapMutations("common", [
      "updateMenuList",
      "updateMainTabsActiveName",
      "updataAllmenuList",
    ]),
    ...mapMutations("user", ["updateuserName", "updateName"]),
    geUserInfo() {
      const userName = sessionStorage.getItem("userName");
      const name = sessionStorage.getItem("Name");
      // 过滤出需要展示的菜单
      const menulist = JSON.parse(sessionStorage.getItem("menuList")).filter(
        (val) => {
          return val.isButton === "0" && val.isShow === "1";
        }
      );
      let arr = [];
      menulist.forEach((m) => {
        if (m.parent == 0) {
          arr = arr.concat(this.convertListToTree(menulist, m));
        }
      });
      this.updataAllmenuList(JSON.parse(sessionStorage.getItem("menuList")));
      this.updateuserName(userName);
      this.updateName(name);
      this.updateMenuList(arr);
      this.updateMainTabsActiveName("home");
    },
    /**
     * 处理list转树结构
     */ convertListToTree(arr, root) {
      const stack = [];
      const array = [];
      let start = false;
      stack.push({ ...root });
      while (stack.length !== 0) {
        const node = stack.pop();
        const children = arr.filter((item) => {
          return item.parent && item.parent === node.id;
        });
        node.children = children;
        if (children.length > 0) {
          node.hasChildren = true;
          for (let i = children.length - 1; i >= 0; i--) {
            stack.push(children[i]);
          }
        }
        if (!start) {
          start = true;
          array.push(node);
        }
      }
      return array;
    },
    // 转化成树形结构的菜单
    treeDataTranslate(data, id = "id", parent = "parent") {
      var res = [];
      var temp = {};
      for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i];
      }
      for (var k = 0; k < data.length; k++) {
        if (temp[data[k][parent]] && data[k][id] !== data[k][parent]) {
          if (!temp[data[k][parent]]["children"]) {
            temp[data[k][parent]]["children"] = [];
          }
          if (!temp[data[k][parent]]["_level"]) {
            temp[data[k][parent]]["_level"] = 1;
          }
          data[k]["_level"] = temp[data[k][parent]]._level + 1;
          temp[data[k][parent]]["children"].push(data[k]);
        } else {
          res.push(data[k]);
        }
      }
      return res;
    },
    GetConfig() {
      GetParameter().then((res) => {
        const { code, data, msg } = res;
        if (code === 1) {
          sessionStorage.setItem("config", JSON.stringify(data));
        }
      });
    },
  },
};
</script>
