<template>
    <main class="site-content" :class="ActiveId === null ?  'cover-content' : ''">
        <MainSideMenu v-if="ActiveId !== null " />
        <el-tabs v-model="ActiveName"  type="card" @tab-remove="removeTabHandle" @tab-click="selectedTabHandle">
            <el-tab-pane
                v-for="item in mainTabs"
                :key="item.urlName"
                :label="item.title"
                :name="item.urlName"
                :closable="item.urlName !== 'home'"
            >
            <el-dropdown class="site-tabs__tools" :show-timeout="0">
                <i class="el-icon-arrow-down el-icon--right"></i>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="tabsCloseCurrentHandle">关闭当前标签页</el-dropdown-item>
                <el-dropdown-item @click.native="tabsCloseOtherHandle">关闭其它标签页</el-dropdown-item>
                <el-dropdown-item @click.native="tabsCloseAllHandle">关闭全部标签页</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
             <span slot="label" style="font-size:16px"><i style="margin-right:7px" v-if="item.urlName === 'home'" class="el-icon-s-home"></i><span>{{item.title}}</span></span>
             <keep-alive>
              <router-view v-if="item.urlName === ActiveName" :key="$route.meta.id" @close="closeCurrentPage" @closeAll="tabsCloseAllHandle" />
             </keep-alive>
            </el-tab-pane>
        </el-tabs>
    </main>
</template>
<script>
import MainSideMenu from './main-sideMenu.vue'
import { mapState, mapMutations } from 'vuex'
export default {
    data(){
        return{
            ActiveName: null,
            isShow:false,
        }
    },
    components:{
        MainSideMenu
    },
    watch:{
        mainTabsActiveName(val) {
            this.ActiveName = val
        }
    },
    mounted(){
        if(sessionStorage.getItem('cacheData') !== null) {
            this.$router.push('/' + sessionStorage.getItem('cacheData'))
            this.ActiveName = sessionStorage.getItem('cacheData')
            this.updateMainTabsActiveName(sessionStorage.getItem('cacheData'))
        }
    },
    computed:{
        ...mapState("common",['menuActiveName','mainTabs','mainTabsActiveName','ActiveId'])
    },
    methods:{
        ...mapMutations("common", ['updateMainTabsActiveName','updateMainTabs','updateMenuActiveName','updataActiveId']),
        selectedTabHandle(tab){
        tab = this.mainTabs.filter(item => item.urlName === tab.name)
        if (tab.length >= 1) {
            const url = '/' +  tab[0].urlName
            if (this.$route.path !== url) {
                this.$router.push({ path: '/' +  tab[0].urlName, query: tab[0].query, params: tab[0].params })
            }
        }
        },
        removeTabHandle(tabName){
            let tablist = this.mainTabs
            tablist = this.mainTabs.filter(item => item.urlName !== tabName)
            this.updateMainTabs(tablist)
        if (this.mainTabs.length >= 1) {
          // 当前选中tab被删除
          if (tabName === this.mainTabsActiveName) {
            var tab = this.mainTabs[this.mainTabs.length - 1]
            this.$router.push({ path: '/' + tab.urlName, query: tab.query, params: tab.params }, () => {
              this.updateMainTabsActiveName(this.$route.name)
            })
          }
        } else {
          this.updateMainTabsActiveName('')
          this.$router.push({path: '/home'})
        }
        },
        tabsCloseCurrentHandle(){
            this.removeTabHandle(this.mainTabsActiveName)
            console.log(this.mainTabsActiveName);
        },
        tabsCloseAllHandle () {
        this.updateMainTabs([{title: '首页', urlName: 'home'}])
        this.updateMainTabsActiveName('')
        this.$router.push({path: '/home'})
        this.updataActiveId(null)
        
      },
      tabsCloseOtherHandle(){
        let list = this.mainTabs.filter(item => item.urlName === this.mainTabsActiveName)
        list.unshift({title: '首页', urlName: 'home'})
        this.updateMainTabs(list)
      },
      closeCurrentPage(name){
          this.removeTabHandle(name)
      }
    }
}
</script>
<style lang="scss" scoped>
</style>